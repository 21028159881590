import React from "react";
import "../styles/Whatsappcontact.css";

export default function WhatsappContact() {
  return (
    <div className="floating-button">
      <a
        className="anchor-whatsapp"
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/5493547546784/?text=%20Hola,%20quisiera%20tener%20mayor%20información"
      >
        <button className="btn-whatsapp"></button>
      </a>
    </div>
  );
}
