import React from "react";
import HomeIcon from "../assets/img/real-estate.png";
import DirectlyIcon from "../assets/img/acuerdo.png";
import ContractIcon from "../assets/img/contrato.png";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function Features() {
  AOS.init();
  return (
    <section
     data-aos="zoom-in" 
     data-aos-duration="1000"
      className="bg-neutral-950 min-h-[025vw] text-gray-200 flex justify-center flex-wrap lg:gap-14  md:gap-8 items-center p-10 ">
      <div className="lg:w-[25%] flex flex-col justif y-center items-center p-7">
        <h2 className="text-[2.5rem] font-extrabold leading-none tracking-wide font-sans ">Publicá tu <span className="block" >propiedad</span></h2>
        <img alt="img-house" src={HomeIcon} className="w-[80%] p-4" />
        <p className="text-justify font-light text-sm text-gray-300">
        Publicá con nosotros y aprovecha nuestro expertos en el mercado inmobiliario para una visibilidad óptima. Confía en JDYC para alcanzar tus metas.
        </p>
        <a             target="_blank"
            rel="noreferrer"
            href="https://wa.me/5493547546784/?text=%20Hola,%20quisiera%20publicar%20mi%20propiedad%20"
             className="border px-8 py-3 m-3 border-gray-600 w-40 bg-neutral-700 border-neutral-700 text-center">Publicá</a>
      </div>

      <div className="lg:w-[25%] flex flex-col justify-center items-center p-7">
      <h2 className="text-[2.5rem] font-extrabold leading-none tracking-wide font-sans ">Fácil y<span className="block" >seguro</span></h2>
        <img alt="img-house" src={DirectlyIcon} className="w-[80%] p-4" />
        <p className="text-justify font-light text-sm text-gray-300">
        La simplicidad y la seguridad son nuestra promesa. Conectá fácilmente con propietarios y disfruta de transacciones seguras sin complicaciones.
        </p>
        <Link to={'/propiedades'} className="border px-8 py-3 m-3 border-gray-600 w-40 bg-neutral-700 border-neutral-700 text-center ">Propiedades</Link>
      </div>

      <div className="lg:w-[25%] flex flex-col justify-center items-center p-7">
      <h2 className="text-[2.5rem] font-extrabold leading-none tracking-wide font-sans ">Sin <span className="block" >intermediarios</span></h2>
        <img alt="img-house" src={ContractIcon} className="w-[80%] p-4" />
        <p className="text-justify font-light text-sm text-gray-300">
        Alcanza tus metas sin intermediarios. Con JDYC Negocios Inmobiliarios, obtén asesoramiento personalizado y directo para tus proyectos. 
        </p>
        <Link to={'/propiedades?tipo=ventas'} className="border px-8 py-3 m-3 border-gray-600 w-40 bg-neutral-700 border-neutral-700 text-center ">Ventas</Link>
      </div>
    </section>
  );
}

export default Features;
