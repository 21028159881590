import React from "react";
import { CiLocationArrow1 } from "react-icons/ci";
import { GrSecure } from "react-icons/gr";
import { HiArrowPath } from "react-icons/hi2";
import { IoIosFingerPrint } from "react-icons/io";
import AOS from "aos";
import "aos/dist/aos.css";
function FAQs() {
  AOS.init();

  const features = [
    {
      name: '¿Cómo puedo comprar una propiedad a través de JDYC Negocios Inmobiliarios?',
      description:
        'Puedes comprar una propiedad a través de JDYC Negocios Inmobiliarios explorando nuestro catálogo en línea y contactándonos para programar una visita o solicitar más información sobre el proceso de compra.',
      icon: CiLocationArrow1,
    },
    {
      name: '¿Qué documentación necesito para iniciar el proceso de compra o venta de una propiedad?',
      description:
        'Los requisitos de documentación pueden variar según el tipo de propiedad y la ubicación. Nuestro equipo te proporcionará una lista detallada de la documentación necesaria para iniciar el proceso de compra o venta.',
      icon: GrSecure,
    },
    {
      name: '¿Qué tipos de propiedades están disponibles para la venta o alquiler en JDYC Negocios Inmobiliarios?',
      description:
        'En JDYC Negocios Inmobiliarios ofrecemos una variedad de propiedades, incluyendo casas, departamentos, terrenos y locales comerciales, disponibles para la venta o alquiler en diversas ubicaciones y rangos de precios.',
      icon: HiArrowPath,
    },
    {
      name: '¿Qué medidas de seguridad implementa JDYC Negocios Inmobiliarios para proteger mis datos personales durante el proceso de compra o venta de una propiedad?',
      description:
        'En JDYC Negocios Inmobiliarios, implementamos medidas de seguridad avanzadas para proteger tus datos personales durante todo el proceso de compra o venta de una propiedad. Cumplimos con las regulaciones de privacidad y nos comprometemos a garantizar la confidencialidad y seguridad de tu información en todo momento.',
      icon: IoIosFingerPrint,
    },
  ]
  return (

    <div data-aos="zoom-out"
      data-aos-duration="1500" className="pt-8 pb-20 border-t">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">

          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Preguntas frecuentes
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-12">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-2 flex h-10 w-10 items-center justify-center rounded-lg bg-orange-500">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-sm leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}

export default FAQs;
