import React, { useState, useEffect } from "react";
import Brochure from "../Brochure";
import BackgroungImage from "../../assets/background/back02.jpg";
import PremiunCard from "../pureComponentes/PremiunCard";
import { IoIosArrowDown } from "react-icons/io";
import { supabase } from "../../supabaseClient";

import monogramaOrange from '../../assets/horizontal/logoHN.png'

// function Properties() {
//   const [realStateProps, setRealStateProps] = useState(dataProps);
//   const [selectedProperty, setSelectedProperty] = useState("Todos");
//   const [selectedType, setSelectedType] = useState("Todos");
//   const [selectedCurrency, setSelectedCurrency] = useState("Todos");

//   const [properties, setProperties] = useState([])

//   const fetchData = async () => {
//     await supabase.from('properties').select('*').then((response) => {
//     //  setProperties(response.data)
//       setRealStateProps(response.data)
//       console.log(response.data)
//     }).catch((err) => {
//       console.error(err)
//     })
//   }



//   useEffect(() => {
//     let filteredProps = realStateProps;

//     if (selectedProperty !== "Todos") {
//       filteredProps = filteredProps.filter(prop => prop.tipo === selectedProperty);
//     }

//     if (selectedType !== "Todos") {
//       filteredProps = filteredProps.filter(prop => prop.operacion.toLowerCase() === selectedType.toLowerCase());
//     }

//     if (selectedCurrency !== "Todos") {
//       filteredProps = filteredProps.filter(prop => prop.moneda.toLowerCase() === selectedCurrency.toLowerCase());
//     }

//     setRealStateProps(filteredProps);

//   }, [selectedProperty, selectedType, selectedCurrency]);

//   useEffect(() => {
//     fetchData()
//   }, [])

//   return (
//     <main>
//       <section
//         style={{ backgroundImage: `url(${BackgroungImage})` }}
//         className="w-full bg-cover bg-center min-h-[30vw] py-10 bg-opacity-90 opacity-90 flex"
//       >
//         <Brochure />
//       </section>
//       <div className="min-h-[4rem] flex justify-center items-center gap-4 lg:flex-row mt-4 md:flex-col flex-wrap">

//         <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
//           <label htmlFor="property" className="text-gray-400">
//             Propiedades
//           </label>
//           <select
//             id="property"
//             name="property"
//             className="h-full border rounded-md bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
//             value={selectedProperty}
//             onChange={(e) => setSelectedProperty(e.target.value)}
//           >
//             <option value="Todos">Todos</option>
//             <option value="Lote">Lotes</option>
//             <option value="Casa">Casas</option>
//             <option value="Depto">Deptos</option>
//             <option value="Turismo">Turismo</option>
//             <option value="Oficinas">Oficinas</option>
//             <option value="Industrial">Industrial</option>
//           </select>
//           <IoIosArrowDown
//             aria-hidden="true"
//             className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-500"
//           />
//         </div>

//         <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
//           <label htmlFor="type" className="text-gray-400">
//             Tipo
//           </label>
//           <select
//             id="type"
//             name="type"
//             className="h-full border rounded-md bg-transparent bg-none py-0 pl-4 pr-4 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
//             value={selectedType}
//             onChange={(e) => setSelectedType(e.target.value)}
//           >
//             <option value="Todos">Todos</option>
//             <option value="venta">Venta</option>
//             <option value="alquiler">Alquiler</option>
//           </select>
//           <IoIosArrowDown
//             aria-hidden="true"
//             className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
//           />
//         </div>

//         <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
//           <label htmlFor="currency" className="text-gray-400">
//             Moneda
//           </label>
//           <select
//             id="currency"
//             name="currency"
//             className="h-full border rounded-md bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
//             value={selectedCurrency}
//             onChange={(e) => setSelectedCurrency(e.target.value)}
//           >
//             <option value="Todos">Todos</option>
//             <option value="USD">Dolares</option>
//             <option value="Pesos">Pesos</option>
//           </select>
//           <IoIosArrowDown
//             aria-hidden="true"
//             className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
//           />
//         </div>

//       </div>
//       <div className="container-md flex flex-wrap justify-center items-center lg:gap-14 gap-8 p-10">
//         {realStateProps?.map((item, index) => (
//           <PremiunCard key={index} item={item} />
//         ))}
//       </div>
//     </main>
//   );
// }
function Properties() {
  const [realStateProps, setRealStateProps] = useState([]);
  const [filteredProps, setFilteredProps] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState("Todos");
  const [selectedType, setSelectedType] = useState("Todos");
  const [selectedCurrency, setSelectedCurrency] = useState("Todos");

  const fetchData = async () => {
    try {
      const { data, error } = await supabase.from("properties").select("*");
      if (error) throw error;
      setRealStateProps(data);
      setFilteredProps(data);
      console.log(data)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let updatedProps = realStateProps;

    if (selectedProperty !== "Todos") {
      updatedProps = updatedProps.filter(
        (prop) => prop.tipo === selectedProperty
      );
    }

    if (selectedType !== "Todos") {
      updatedProps = updatedProps.filter(
        (prop) => prop.operacion.toLowerCase() === selectedType.toLowerCase()
      );
    }

    if (selectedCurrency !== "Todos") {
      updatedProps = updatedProps.filter(
        (prop) => prop.moneda.toLowerCase() === selectedCurrency.toLowerCase()
      );
    }

    setFilteredProps(updatedProps);
  }, [selectedProperty, selectedType, selectedCurrency, realStateProps]);

  return (
    <main>
      <section
        style={{ backgroundImage: `url(${BackgroungImage})` }}
        className="w-full bg-cover bg-center min-h-[30vw] py-10 bg-opacity-90 opacity-90 flex"
      >
        <Brochure />
      </section>
      <div className="min-h-[4rem] flex justify-center items-center gap-4 lg:flex-row mt-4 md:flex-col flex-wrap">
        <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
          <label htmlFor="property" className="text-gray-800">
            Propiedades
          </label>
          <select
            id="property"
            name="property"
            className="h-full border rounded-xl bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            value={selectedProperty}
            onChange={(e) => setSelectedProperty(e.target.value)}
          >
            <option value="Todos">Todos</option>
            <option value="Lote">Lotes</option>
            <option value="Casa">Casas</option>
            <option value="Depto">Deptos</option>
            <option value="Turismo">Turismo</option>
            <option value="Oficinas">Oficinas</option>
            <option value="Industrial">Industrial</option>
          </select>
          <IoIosArrowDown
            aria-hidden="true"
            className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-500"
          />
        </div>

        <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
          <label htmlFor="type" className="text-gray-800">
            Tipo
          </label>
          <select
            id="type"
            name="type"
            className="h-full border rounded-xl bg-transparent bg-none py-0 pl-4 pr-4 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
          >
            <option value="Todos">Todos</option>
            <option value="venta">Venta</option>
            <option value="alquiler">Alquiler</option>
          </select>
          <IoIosArrowDown
            aria-hidden="true"
            className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
          />
        </div>

        <div className="w-fit flex gap-2 items-center h-10 rounded-md px-1">
          <label htmlFor="currency" className="text-gray-800">
            Moneda
          </label>
          <select
            id="currency"
            name="currency"
            className="h-full border rounded-xl bg-transparent bg-none py-0 pl-4 pr-9 text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm"
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
          >
            <option value="Todos">Todos</option>
            <option value="USD">Dolares</option>
            <option value="Pesos">Pesos</option>
          </select>
          <IoIosArrowDown
            aria-hidden="true"
            className="pointer-events-none absolute right-3 top-0 h-full w-5 text-gray-400"
          />
        </div>
      </div>

    {/*  <div className="w-full flex flex-col  justify-center items-center" >

         <img
          className="h-20"
          src={monogramaOrange}
        />
        <h3 className="font-hairline text-gray-800"  >No hay resultados relacionados con su busqueda</h3>
      </div> */}


      <div className="container-md flex flex-wrap justify-center items-center lg:gap-14 gap-8 p-10">
        {/* {filteredProps.map((item, index) => (
          <PremiunCard key={index} item={item} />
        ))} */}
                {filteredProps.length > 0 ? (
          filteredProps.map((item, index) => (
            <PremiunCard key={index} item={item} />
          ))
        ) : (
          <div className="w-full flex flex-col justify-center items-center">
            <img className="h-20" src={monogramaOrange} alt="No results" />
            <h3 className="font-hairline text-gray-800">
              No hay resultados relacionados con su búsqueda
            </h3>
          </div>
        )}
      </div>
    </main>
  );
}

export default Properties;
