import React from "react";
import Hero from "../Hero";
import Features from "../Features";
import Funtionality from "../Funtionality";
import Brochure from "../Brochure";
import ExplorePremiun from "../ExplorePremiun";

import Stats from "../Stats";
//import Cities from "../Cities";
import FAQs from "../FAQs";

import Team from "../Team";
import Tourism from "../Tourism";
import ManagePropertyBanner from "../ManagePropertyBanner";
import Partners from "../Partners";
// import TextCarousel from "../TextCarousel";

function Landing() {
  return (
    <>
      <Hero />
      <Features />
      <Funtionality />
      {/* <TextCarousel /> */}
      {/* <Cities /> */}
      <Tourism/>
      <Brochure title={'Catálogo de propiedades'} />
      <ExplorePremiun />
      <Stats />
      <ManagePropertyBanner/>
      <Team/>
      <Partners/>
      <FAQs />
    </>
  );
}

export default Landing;
